<template>
  <div class="login">
    <div class="login-box">
      <div class="white-l-box text-center">
        <img src="/assets/img/logo-big.png" alt="" />
        <h2 class="bold-19 text-gray mt-2 mb-4">Prodyflow</h2>
        <h2 class="bold-24 text-black mt-3">
          {{ $t("login.welcomeToProdyflow") }}
        </h2>
        <SwitchButtons
          v-model="switch1"
          colClass="col-6 mt-4 mb-3"
          btnClass="w-100 btn-small"
          :buttons="[
            { name: 'Password', value: 'password' },
            { name: 'QR code', value: 'qr' },
          ]"
        />
        <div v-if="secondFactor">
          <h3 class="regular-14 text-gray mt-2 mb-4">
            {{ $t("login.enterYourAuthenticatorCodeBelow") }}
          </h3>
          <form v-on:submit.prevent="twofa()">
            <div class="row text-start custom-input whitebg-input mt-4">
              <div class="col-12">
                <div class="mb-3">
                  <div class="flex-wrap d-flex">
                    <div class="d-flex">
                      <label class="form-label bold-12 text-gray">
                        {{ $t("login.code2") }}
                      </label>
                    </div>
                  </div>
                  <input
                    tabindex="2"
                    type="password"
                    name="code"
                    class="form-control remove-padding"
                    :placeholder="$t(`login.code2`)"
                    v-model="code"
                  />
                </div>
              </div>
            </div>
            <div ref="captcha2"></div>
            <div class="error my-2 text-danger fw-bold" v-if="error">
              {{ error }}
            </div>
            <button type="submit" class="btn btn-type-2 w-100 text-center">
              <span class="bold-14">
                {{ $t("forgottenPassword.logIn") }}
              </span>
            </button>
          </form>
        </div>
        <div v-if="!secondFactor && switch1 === 'qr'">
          <h3 class="regular-14 text-gray mt-2 mb-2">
            {{ $t("login.scanTheQrCode") }}
          </h3>
          <div class="d-none d-sm-block">
            <img :src="qr" width="190" height="190" class="my-2" alt="" />
          </div>
        </div>
        <div v-if="!secondFactor && switch1 === 'password'">
          <h3 class="regular-14 text-gray mt-2 mb-4">
            {{ $t("login.enterYourEmailAndPasswordBelow") }}
          </h3>
          <form v-on:submit.prevent="login()">
            <div class="row text-start custom-input whitebg-input mt-4">
              <div class="col-12">
                <div class="mb-4">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label bold-12 text-gray"
                  >
                    {{ $t("finance.email") }}
                  </label>
                  <input
                    tabindex="2"
                    type="email"
                    name="email"
                    class="form-control remove-padding"
                    id="exampleFormControlInput1"
                    :placeholder="$t(`finance.emailAddress`)"
                    v-model="email"
                  />
                </div>
                <div class="mb-3">
                  <div class="flex-wrap d-flex">
                    <div class="d-flex">
                      <label class="form-label bold-12 text-gray">
                        {{ $t("login.password2") }}
                      </label>
                    </div>
                    <div class="d-flex ms-auto">
                      <router-link
                        tabindex="0"
                        to="/forgotten-password"
                        class="regular-10 text-gray pointer"
                      >
                        {{ $t("login.forgotPassword") }}
                      </router-link>
                    </div>
                  </div>
                  <input
                    tabindex="2"
                    type="password"
                    name="password"
                    class="form-control remove-padding"
                    :placeholder="$t(`catalogImport.password`)"
                    v-model="password"
                  />
                </div>
              </div>
            </div>
            <div ref="captcha"></div>
            <div class="error my-2 text-danger fw-bold" v-if="error">
              {{ error }}
            </div>
            <button type="submit" class="btn btn-type-2 w-100 text-center">
              <span class="bold-14">
                {{ $t("forgottenPassword.logIn") }}
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButtons from "../../components/inputs/SwitchButtons";
import tenant from "../../modules/tenant";
import router from "../../router";
const PusherJS = require("pusher-js");

export default {
  name: "Login",
  components: { SwitchButtons },
  data() {
    return {
      switch1: "password",
      email: "",
      password: "",
      error: "",
      qr: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
      needCaptcha: false,
      captchaID: null,
      wsclient: null,
      secondFactor: false,
      token: null,
    };
  },
  watch: {
    switch1() {
      if (this.switch1 === "qr") {
        this.getQr();
      }
    },
  },
  methods: {
    watchQr(hash, token) {
      if (this.wsclient === null) {
        this.wsclient = new PusherJS(process.env.VUE_APP_PUSHER_KEY, {
          wsHost: process.env.VUE_APP_PUSHER_DOMAIN,
          wsPort: process.env.VUE_APP_PUSHER_PORT,
          httpHost: process.env.VUE_APP_PUSHER_HTTP_HOST,
          authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
          forceTLS: process.env.VUE_APP_PUSHER_FORCETLS === "true",
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          encrypted: true,
          disableStats: true,
          enabledTransports: ["ws", "wss"],
        });
      } else {
        this.wsclient.config.auth.headers.Authorization = `Bearer ${token}`;
      }
      this.wsclient
        .subscribe(`private-qrlogin.${hash}`)
        .bind("QrLoginEvent", () => {
          let data = new FormData();
          data.append("token", hash);
          fetch(`${tenant.getApiBaseUrl()}/auth/validate-qr`, {
            method: "POST",
            body: data,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              this.wsclient.unsubscribe(`qrlogin.${hash}`);
              this.loginOk(data);
            });
        });
    },
    captcha() {
      if (this.needCaptcha) {
        // eslint-disable-next-line no-undef
        hcaptcha.reset(this.captchaID);
      } else {
        this.needCaptcha = true;
        // eslint-disable-next-line no-undef
        this.captchaID = hcaptcha.render(
          this.secondFactor ? this.$refs.captcha2 : this.$refs.captcha,
          {
            sitekey: "40502656-4482-4a91-9999-797738a6dd8d",
          }
        );
      }
    },
    getQr() {
      this.qr =
        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

      fetch(`${tenant.getApiBaseUrl()}/auth/generate-qr`, {
        headers: { Accept: "application/json" },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          this.qr = data.url;
          this.watchQr(data.hash, data.token);
        });
    },
    twofa() {
      let data = new FormData();
      data.append("code", this.code);
      if (this.needCaptcha) {
        // eslint-disable-next-line no-undef
        data.append("h-captcha-response", hcaptcha.getResponse(this.captchaID));
      }
      fetch(`${tenant.getApiBaseUrl()}/auth/check-2fa`, {
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          this.loginOk(data);
        })
        .catch((err) => {
          err.json.then((data) => {
            if (data.needCaptcha || this.needCaptcha) {
              this.captcha();
            }
            this.error = data.message;
          });
        });

      return false;
    },
    login() {
      let data = new FormData();
      data.append("email", this.email);
      data.append("password", this.password);
      if (this.needCaptcha) {
        // eslint-disable-next-line no-undef
        data.append("h-captcha-response", hcaptcha.getResponse(this.captchaID));
      }
      fetch(`${tenant.getApiBaseUrl()}/auth/login`, {
        method: "POST",
        body: data,
        headers: { Accept: "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          if (data.second_factor) {
            this.secondFactor = true;
            this.token = data.token;
          } else {
            this.loginOk(data);
          }
        })
        .catch((err) => {
          err.json.then((data) => {
            if (data.needCaptcha || this.needCaptcha) {
              this.captcha();
            }
            this.error = data.message;
          });
        });

      return false;
    },
    loginOk(data) {
      this.$store.commit("login", data);
      if (data?.tenant?.fist_steps) {
        router.push("/first-steps");
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
